<template>
    <div class="main">
        <div class="yuancard">
            <div style="padding:15px 20px;">
                <div style="text-align:center">
                    <img v-if="!isDL" src="@/assets/img/my-tx@3x.png" class="tuSty">
                    <img v-if="isDL" :src="allData.header_img" class="tuSty">

                    <div style="font-size:17px;" v-if="isDL">{{allData.nick}}</div>
                    <div v-else>未登录</div>
                    <!-- <div style="margin-bottom:15px;color:#666;font-size:14px;">{{allData.Phone}}</div> -->
                </div>
                
            </div>
        </div>
        <div style="margin:50px 0;" v-if="!isDL">
            <van-button block round type="primary"  @click="record">登 录</van-button>
        </div>

        <div v-if="isDL">
            <div v-if="isShowJCe">
            
                <div>发票服务:</div>
                <div class="bottombtn" v-if="allData.mexist !='0'">
                    <div>
                        <van-row class="huizi">
                            <van-col span="6" class="colmin">商户名称：</van-col>
                            <van-col span="16">{{nsrmc}}</van-col>
                        </van-row>
                    
                    </div>
                    <van-grid :border="false" center :icon-size="32" :column-num="2">
                        <van-grid-item v-show="control_yhbtn" :icon="require('@/assets/img/my-xgmm@3x.png')" text="修改密码" @click="mmshow=true" />
                        <!-- <van-grid-item v-show="!control_yhbtn" :icon="require('@/assets/img/my-xgmm@3x.png')" text="新增用户" @click="yhzcClick" /> -->
                        <!-- <van-grid-item :icon="require('@/assets/img/my-dl@3x.png')" text="登录" @click="Dlu" /> -->
                        <van-grid-item :icon="require('@/assets/img/my-zx@3x.png')" text="注销" @click="ZXiao" />
                    </van-grid>
                    
                </div>
                <div style="margin:40px 0;" v-if="allData.mexist =='0'">
                    <van-button block round type="primary"  @click="Dlu">绑 定</van-button>
                </div>
            </div>

            <div style="margin-top:40px;">绑定企业:
                <span v-if="allData.dexist !='0'" @click="yhzcClick" style="color: #1989fa; margin-left: 10px;">新增用户</span>

            </div>
            <div class="bottombtn" v-if="allData.dexist !='0'">
                <div>
                    <van-row class="huizi">
                        <van-col span="6" class="colmin">企业名称：</van-col>
                        <van-col span="16">{{dqQYobj.merchant_name}}</van-col>
                    </van-row>
                
                </div>
                <van-grid :border="false" center :icon-size="32" :column-num="2">
                    <van-grid-item :icon="require('@/assets/img/qhyh@3x.png')" text="切换用户" @click="yhqhClick" />
                    <van-grid-item :icon="require('@/assets/img/my-zx@3x.png')" text="解绑" @click="JieBang" />
                </van-grid>
                
            </div>
            <div style="margin:40px 0;" v-if="allData.dexist =='0'">
                <van-button block round type="primary"  @click="yhzcClick">绑定企业</van-button>
            </div>

        </div>
        
    </div>
    <Footer></Footer>

    <van-popup v-model:show="mmshow" round closeable>
        <div style="min-width:90vw;min-height:50vh;">
            <van-cell-group style="padding-top:30px;">
                <van-cell>
                    <van-field required v-model="mmData.pwd" type="password" label="原密码" placeholder="请输入原密码"/>
                </van-cell>
                <van-cell>
                    <van-field required v-model="mmData.pwdn1" type="password" label="新密码" placeholder="请输入新密码"/>
                </van-cell>
                <van-cell>
                    <van-field required v-model="mmData.pwdn2" type="password" label="确认密码" placeholder="请再次输入新密码"/>
                </van-cell>
            </van-cell-group>

            <div style="margin:40px;">
                <van-button round block type="primary" @click="XGmm">确认修改</van-button>
            </div>
        </div>
        
    </van-popup>

    <van-popup v-model:show="qyxxshow" :style="{ width: '90%'  }"  >
      <van-cell-group>
        <van-cell center v-for="item in allData.dsfs" :key = item :title="item.merchant_name" @click="xzClick(item)">
          <template #right-icon>
            <van-icon v-if= "allData.mrqy == item.id" name="passed"/>
          </template>
        </van-cell>
      </van-cell-group>
    </van-popup>

    <!-- 遮罩loading -->
    <van-overlay :show="showload" @click="showload = false" >
        <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
    </van-overlay>

</template>

<script>
import Footer from "../../components/Tabbar/index.vue";
import {reactive, ref, getCurrentInstance, onMounted, computed} from 'vue'
import {Dialog, Notify} from 'vant';
import loginRequest from '../../api/login'
import getUserInfo from '@/mixin/getUserInfo.js'
import {useStore} from "vuex";
import {getLocalStorage,setLocalStorage} from '../../utils/local-storage'
import config from "../../config";
import wx from 'weixin-js-sdk';

export default {
    name: "mine",
    components: {
        Footer
    },
    setup() {
        
        const {proxy} = getCurrentInstance();
        const store = useStore();
        const mmshow = ref(false);
        const mmData = reactive({
            pwd:"",
            pwdn1:"",
            pwdn2:"",

        });
        const XGmm = () =>{
            console.log(mmData);
            if(mmData.pwd==""){
                Notify({ type: 'warning', message: '原密码不能为空!' });
                return
            }
            if(mmData.pwdn1==""){
                Notify({ type: 'warning', message: '新密码不能为空!' });
                return
            }
            if(mmData.pwdn2==""){
                Notify({ type: 'warning', message: '确认密码不能为空!' });
                return
            }
            loginRequest.wx_user_modify_pwd(mmData).then(res=>{
                if(res.returnCode == "00"){
                    mmshow.value=false;
                    Dialog.alert({
                        title: '温馨提示',
                        message: res.returnMessage,
                    }).then(() => {});
                }
                
            });
        };
        
        const record = () =>{
            // alert('111111111111')
            wx.miniProgram.navigateTo({url: '/pages/login/index'})
            
        };
        const Dlu = () =>{
            proxy.$root.$router.push({path: "/login", params: {"fp": 1}});
        };
        const ZXiao = () =>{
            Dialog.confirm({
                title: '温馨提示',
                message: '是否确认注销账号',
            }).then(() => {
                loginRequest.wx_user_logout({}).then(res=>{
                    
                    if(res.returnCode == "00"){
                        Dialog.alert({
                            title: '温馨提示',
                            message: res.returnMessage,
                        }).then(() => {
                            proxy.$root.$router.go(0);

                        });
                        setLocalStorage({"Authorization": "undefined"});
                        getUserInfo(proxy.$root.$route, store);
                        
                    }

                });
            }).catch(() => {});
        };
        const sbxxdata =ref({});
        const isDL =ref(false);
        const showload = ref(false);
       

        const allData =ref({});
        const dqQYobj =ref({});
        const huanCun= () =>{
          if(sessionStorage.getItem("isDenglu") == "true"){
            isDL.value=true;

            let t_auth = getLocalStorage(config.request.localKey);
            console.log(t_auth)
            if(t_auth!=undefined){
                let t_auth_infos = t_auth[config.request.localKey];
                console.log(t_auth_infos)
                if(t_auth_infos!= 'undefined'&& t_auth_infos!= 'null'){
                   var  obj_infos = JSON.parse(t_auth_infos);
                    allData.value = obj_infos;
                    console.log(allData);
                    console.log(allData.value.dsfs);
                    if(allData.value.dsfs.length>0){
                        for(var i=0;i<allData.value.dsfs.length;i++){
                            var aa= allData.value.dsfs[i]
                            if(aa.id == allData.value.mrqy){
                                dqQYobj.value = aa
                            }
                        }
                    }
                }else{
                    location.reload();
                }
            }

          }else{
            isDL.value=false;
          }
          // console.log(isDL);
        }

        const qyxxshow = ref(false);
        const nsrArr = computed(() => store.state.nsrUserArr);
        const nsrmc = computed(() => store.state.nsrUserModel.merchant_name);
        const yhqhClick = () =>{
          qyxxshow.value = true;
        };
        const xzClick = (item)=>{
        //   if (allData.value.mrqy === item.id) {
        //     return;
        //   }
          loginRequest.wx_user_modify_mrqy({mryh: item.id}).then(res=>{
            if (res.returnCode === "00"){
                Dialog.alert({
                    title: '温馨提示',
                    message: res.returnMessage,
                }).then(() => {
                    proxy.$root.$router.go(0);
                    qyxxshow.value = false;
                });
                
                getUserInfo(proxy.$root.$route, store);
               
            }

          });
        };

        const control_yhbtn = ref(true);

        const yhzcClick = () =>{
            proxy.$root.$router.push({path: "/intellectBS"});
        };
        const JieBang = () =>{
          Dialog.confirm({
                title: '温馨提示',
                message: '是否确认解绑企业',
            }).then(() => {
                loginRequest.wx_user_logout_dsf({}).then(res=>{
                    
                    if(res.returnCode == "00"){
                        Dialog.alert({
                            title: '温馨提示',
                            message: res.returnMessage,
                        }).then(() => {
                            proxy.$root.$router.go(0);

                        });
                        setLocalStorage({"Authorization": "undefined"});
                        getUserInfo(proxy.$root.$route, store);
                        
                    }

                });
            }).catch(() => {});
        };

        const isShowJCe = ref(false);
        // console.log(sessionStorage.getItem('isJianCe'))
        if(sessionStorage.getItem('isJianCe')=='0'){
            isShowJCe.value=true;
        } else{
            isShowJCe.value=false;
        }

        onMounted(()=>{
           
            huanCun();

        });

        return{
            XGmm,
            Dlu,
            ZXiao,
            mmshow,
            mmData,
            
            sbxxdata,
            isDL,
            huanCun,
            showload,
            qyxxshow,
            nsrArr,
            yhqhClick,
            xzClick,
            control_yhbtn,
            yhzcClick,
            record,
            allData,
            nsrmc,
            dqQYobj,
            JieBang,
            isShowJCe
        }
    }
}
</script>

<style scoped>
    .main {
        /* background:#eee; */
        background-image: url('../../assets/img/my-bj@3x.png');
        background-repeat:  no-repeat;
        background-size:100% 20%;
        margin: 0;
        padding:50px 15px 10px;
        min-height: 100vh;
    }

    .huizi{
        color: #666;
        font-size: 14px;
        margin: 5px 0;
    }
    .bottombtn {
        border-radius: 10px;
        box-shadow: 4px 4px 12px #ddd;
        padding: 5px;
    }
    .yuancard {
        width: 100%;
        min-height: 20%;
        margin: 50px 0px 40px;
        background: #fff right bottom ;
        background-image: url('../../assets/img/my-dn@3x.png');
        background-size: 30% 70%;
        background-repeat: no-repeat;
        border-radius: 10px;
        box-shadow: 4px 4px 12px #ddd;
        
    }
    .tuSty{
        width: 25%;
        margin: -18% 0 0 0;
    }
    .colmin{
        min-width: 75px;
        text-align: right;
    }
</style>